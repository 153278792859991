<template>
	<Modal
		:title="title"
		:value="visible"
		width="350"
		class="add-process-wrap"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Form :model="formData" ref="form" label-position="top" :rules="rules">
			<Row :gutter="32">
				<Col span="24">
					<FormItem label="流程名称" prop="name">
						<Input v-model="formData.name" placeholder="请输入流程名称" />
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="流程图分类(新增后不可修改)" prop="category">
						<Select
							v-model="formData.category"
							:label-in-value="true"
							:disabled="!!updateId && !type"
							placeholder="请选择"
						>
							<Option
								:value="list.obj.nickname"
								v-for="list in cateList"
								:key="list.obj.nickname"
								:label="list.name"
							></Option>
						</Select>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="新增项目时是否默认配置此流程" prop="isDefault">
						<i-switch
							v-model="formData.isDefault"
							:true-value="1"
							:false-value="0"
							@on-change="checked => switchChange(checked, 'isDefault')"
							>>
							<span slot="open">是</span>
							<span slot="close">否</span>
						</i-switch>
					</FormItem>
				</Col>
				<Col span="24" v-if="env === 'xinhua' && formData.isDefault === 1">
					<FormItem label="完成阶段时，是否必须完成此流程" prop="isMustExecute">
						<i-switch
							v-model="formData.isMustExecute"
							:true-value="1"
							:false-value="0"
							@on-change="checked => (formData.isMustExecute = checked)"
							>>
							<span slot="open">是</span>
							<span slot="close">否</span>
						</i-switch>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="是否是子流程" prop="isSubProcess">
						<i-switch
							v-model="formData.isSubProcess"
							:true-value="1"
							:false-value="0"
							@on-change="checked => (formData.isSubProcess = checked)"
							>>
							<span slot="open">是</span>
							<span slot="close">否</span>
						</i-switch>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="文档配置" prop="docConfigId">
						<Select
							v-model="formData.docConfigId"
							:label-in-value="true"
							placeholder="请选择"
						>
							<Option v-for="item in list" :value="item.id" :key="item.id">
								{{ item.name }}（{{ item.version }}）
							</Option>
						</Select>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="启动流程是否开启角色控制" prop="startControl">
						<i-switch
							v-model="formData.startControl"
							:true-value="1"
							:false-value="0"
							@on-change="checked => switchChange(checked, 'startControl')"
							>>
							<span slot="open">是</span>
							<span slot="close">否</span>
						</i-switch>
					</FormItem>
				</Col>
				<Col span="24" v-if="formData.startControl === 1">
					<FormItem label="启动流程角色" prop="roleIds">
						<Select
							v-model="formData.roleIds"
							multiple
							placeholder="请选择启动流程角色"
						>
							<Option
								:value="role.id"
								v-for="role in roleList"
								:key="role.id"
								:label="role.name"
							></Option>
						</Select>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="是否通用流程，适用于全部项目类型" prop="isCommon">
						<i-switch
							v-model="formData.isCommon"
							class="custom-switch"
							:true-value="1"
							:false-value="0"
							@on-change="checked => switchChange(checked, 'isCommon')"
							>>
							<span slot="open">是</span>
							<span slot="close">否</span>
						</i-switch>
					</FormItem>
				</Col>
				<Col span="24" v-if="formData.isCommon === 0">
					<FormItem label="本流程适用的项目类型" prop="projectConfigIds">
						<Select
							v-model="formData.projectConfigIds"
							multiple
							placeholder="本流程适用的项目类型"
						>
							<Option
								:value="item.id"
								v-for="item in projTypeList"
								:key="item.id"
								:label="item.typeName"
							></Option>
						</Select>
					</FormItem>
				</Col>
				<Col span="24" v-if="env === 'xinhua'">
					<FormItem label="选择开启短信通知类型" prop="processNotifyType">
						<Select
							v-model="formData.processNotifyType"
							clearable
							placeholder="请选择要开启的短信通知类型"
						>
							<Option
								:value="item.notifyType"
								v-for="item in notifyTypeList"
								:key="item.notifyType"
								:label="item.notifyName"
							></Option>
						</Select>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="流程进度中的颜色显示" prop="color">
						<RadioGroup v-model="formData.color">
							<Radio :label="0">
								<span class="color-item pink">活力粉</span>
							</Radio>
							<Radio :label="1">
								<span class="color-item blue">淡雅蓝</span>
							</Radio>
							<Radio :label="2">
								<span class="color-item green">生命绿</span>
							</Radio>
						</RadioGroup>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="描述" prop="description">
						<Input
							v-model="formData.description"
							type="textarea"
							placeholder="请输入描述"
							:rows="5"
						/>
					</FormItem>
				</Col>
			</Row>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button type="primary" @click="onOk" :loading="loading">确定</Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/setting/process"
import docConfigApi from "@/api/setting/docConfig"

const { apiGet, apiAdd, apiUpdate, apiCopyProcess, apiTypeProcess } = api

const { getConfigList } = docConfigApi
export default {
	name: "Add",
	props: [
		"visible",
		"updateId",
		"cateList",
		"type",
		"roleList",
		"projTypeList"
	],
	data() {
		return {
			loading: false,
			formData: {
				isDefault: 0,
				startControl: 0,
				roleIds: [],
				isCommon: 1,
				processNotifyType: null,
				projectConfigIds: [],
				isSubProcess: 0,
				isMustExecute: 0,
				color: 0
			},
			title: "",
			rules: {
				name: {
					required: true,
					message: "流程名称不能为空",
					trigger: "blur"
				},
				category: {
					required: true,
					message: "流程图分类不能为空",
					trigger: "blur"
				},
				roleIds: {
					required: true,
					type: "array",
					message: "启动流程角色不能为空",
					trigger: "change"
				},
				projectConfigIds: {
					required: true,
					type: "array",
					message: "本流程适用的项目类型不能为空",
					trigger: "change"
				}
			},
			list: [],
			notifyTypeList: []
		}
	},
	computed: {
		env() {
			return this.$store.state.environment
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.$refs.form.resetFields()
				this.formData = {
					isDefault: 0,
					startControl: 0,
					roleIds: [],
					isCommon: 1,
					processNotifyType: null,
					projectConfigIds: [],
					isSubProcess: 0,
					isMustExecute: 0,
					color: 0
				}
				this.title = "新增流程"
				this.initList()
				this.getNotifyTypeList()
				if (this.updateId) {
					this.title = this.type ? "复制流程" : "修改流程"
					this.$asyncDo(async () => {
						this.loading = true
						const res = await apiGet({ id: this.updateId })
						if (res) {
							this.formData = {
								...this.formData,
								...res.data,
								isMustExecute: res.data.isMustExecute || 0,
								color: res.data.color || 0,
								isSubProcess: res.data.isSubProcess || 0,
								startControl: res.data.startControl || 0,
								roleIds: res.data.roleIds ? res.data.roleIds.split(",") : [],
								isCommon: Number.isNaN(parseInt(res.data.isCommon, 10))
									? 1
									: parseInt(res.data.isCommon, 10),
								processNotifyType: res.data.processNotifyType || null,
								projectConfigIds: res.data.projectConfigIds || []
							}
						}
						this.loading = false
					})
				}
			}
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		async initList() {
			const res = await getConfigList({ type: 1 })
			if (res) {
				this.list = res.data || []
			}
		},
		async getNotifyTypeList() {
			const res = await apiTypeProcess()
			if (res) {
				this.notifyTypeList = res.data || []
			}
		},
		switchChange(value, switchtype) {
			this.formData[switchtype] = value
			if (switchtype === "startControl" && value === 0) {
				this.formData.roleIds = []
			}
			if (switchtype === "isCommon" && value === 1) {
				this.formData.projectConfigIds = []
			}
			if (switchtype === "isDefault" && value === 0) {
				this.formData.isMustExecute = 0
			}
		},
		onOk() {
			this.$refs.form.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						let res = false
						if (this.updateId) {
							if (this.type) {
								res = await apiCopyProcess({
									...this.formData,
									modelId: this.updateId
								})
							} else {
								res = await apiUpdate({
									...this.formData,
									id: this.updateId
								})
							}
						} else {
							res = await apiAdd({
								...this.formData
							})
						}
						if (res) {
							this.$emit("onOk")
							this.$Message.success("操作成功！")
						}
						this.loading = false
					})
				}
			})
		}
	}
}
</script>

<style lang="less">
.add-process-wrap {
	.color-item {
		padding: 1px 13px 0;
		line-height: 26px;
		height: 24px;
		display: inline-block;
		vertical-align: middle;
		border-radius: 4px;
		&.pink {
			color: #ff0033;
			background: #ffeef2;
		}
		&.blue {
			color: #144cff;
			background: #ebf0fe;
		}
		&.green {
			color: #00a321;
			background: #e9f7e3;
		}
	}
}
</style>
