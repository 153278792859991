<template>
	<Modal
		title="打印表单排序"
		:value="visible"
		width="350"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<div v-if="formList.length">
			<p style="font-size: 14px; color: red; margin-bottom: 10px">拖拽排序</p>
			<Draggable
				v-model="formList"
				:options="{
					draggable: '.form-item',
					animation: 150,
					ghostClass: 'active',
					dragClass: 'active'
				}"
			>
				<p v-for="(item, index) in formList" class="form-item" :key="index">
					{{ item.nodeName }}-{{ item.formName }}
				</p>
			</Draggable>
		</div>
		<div v-else>
			<p style="padding-top: 150px; text-align: center; font-size: 14px">
				暂无数据
			</p>
		</div>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel"> 取消 </Button>
			<Button type="primary" @click="onOk"> 确定 </Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/setting/process"
import Draggable from "vuedraggable"

const { apiGetFormList, apiFormListOrder } = api
export default {
	name: "FormOrder",
	props: ["id", "visible"],
	components: {
		Draggable
	},
	data() {
		return {
			formList: [],
			loading: false
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiFormListOrder({
					id: this.id,
					formSort: this.formList
				})
				if (res) {
					this.$Message.success("操作成功")
					this.$emit("onOk")
				}
				this.loading = false
			})
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.formList = []
				if (this.id) {
					this.$asyncDo(async () => {
						this.loading = true
						const res = await apiGetFormList({ id: this.id })
						if (res) {
							this.formList = res.data
						}
						this.loading = false
					})
				}
			}
		}
	}
}
</script>

<style scoped lang="less">
.form-item {
	margin: 15px auto;
	font-size: 14px;
	padding: 5px 10px;
	color: #848586;
	border: 1px solid #a6a6ae;
	border-radius: 5px;
	cursor: pointer;
	&.active {
		color: #fd9f21;
		box-shadow: 0px 2px 10px 0px rgba(253, 150, 46, 0.2);
		border: solid 1px #fd9f21;
	}
}
</style>
