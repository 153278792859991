<template>
	<div class="content-wrap process-index-wrap">
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="流程名称">
				<Input v-model="searchParams.nameLike" placeholder="请输入流程名称" />
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="流程图分类">
				<Select
					v-model="searchParams.category"
					:transfer="true"
					:label-in-value="true"
					placeholder="请选择"
				>
					<Option
						:value="list.obj.nickname"
						v-for="list in processList"
						:key="list.obj.nickname"
						:label="list.name"
					></Option>
				</Select>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="状态">
				<Select
					v-model="searchParams.isDisable"
					:transfer="true"
					placeholder="请选择状态"
					clearable
				>
					<Option :value="0">启用</Option>
					<Option :value="1">禁用</Option>
				</Select>
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsAction>
			<Button
				type="primary"
				@click="handleAddShow"
				v-if="
					!(systemActionPermissions.indexOf('btn_setting_process_add') === -1)
				"
				>新增</Button
			>
			<Button
				@click="handleSort"
				type="primary"
				v-if="
					!(systemActionPermissions.indexOf('btn_setting_process_add') === -1)
				"
				>流程排序</Button
			>
			<Poptip word-wrap placement="bottom" width="480">
				<Button>子流程配置参考</Button>
				<p slot="content">
					被调用元素 - ${callActivityId} 多实例类型 - Parallel 集合（多实例） -
					fillFormUserList 元素变量（多实例） - fillFormUser 完成条件（多实例）
					- ${nrOfCompletedInstances/nrOfInstances == 1} 输入参数：
					（源表达式不用填） 源 目标 fillFormUser fillFormUser sendSysMessage
					sendSysMessage fillFormRoleId fillFormRoleId fillFormIdList
					fillFormIdList sonUserFormsMap sonUserFormsMap lastRemark lastRemark
				</p>
			</Poptip>
			<Button
				@click="handleToProcess"
				type="primary"
				v-if="$route.query.projectId"
				>返回流程管理页面</Button
			>
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="filterListData"
			:page="false"
		></CtmsDataGrid>
		<!--    新增、修改流程-->
		<Add
			:visible="addVisible"
			@onCancel="addVisible = false"
			:cateList="processList"
			:role-list="roleList"
			:proj-type-list="projTypeList"
			@onOk="handleAddOk"
			:updateId="updateId"
			:type="type"
		></Add>
		<!--    表单排序-->
		<FormOrder
			:visible="formOrderVisible"
			@onCancel="formOrderVisible = false"
			@onOk="formOrderVisible = false"
			:id="formOrderId"
		></FormOrder>
		<!--    流程排序-->
		<ProcessOrder
			:visible="processOrderVisible"
			@onCancel="processOrderVisible = false"
			@onOk="handleProcessSortOk"
			:processData="listData"
			:cateList="processList"
		></ProcessOrder>
	</div>
</template>

<script>
import proApi from "@/api/project/project"
import api from "@/api/setting/process"
import { mapState } from "vuex"
import Add from "./Add.vue"
import FormOrder from "./FormOrder.vue"
import ProcessOrder from "./ProcessOrder.vue"

const { apiGetProjTypeList } = proApi
const { apiGetPage, apiDelete, apiDisableProcess, apiGetRoleList } = api

export default {
	name: "index",
	components: {
		Add,
		FormOrder,
		ProcessOrder
	},
	data() {
		return {
			listData: [],
			columns: [
				{
					title: "流程名称",
					key: "name",
					minWidth: 200
				},
				{
					title: "流程图分类",
					key: "category.label",
					render: (h, params) => {
						const item = this.processList.find(
							o => o.obj.nickname === params.row.category
						)
						const txt = item ? item.name : ""
						return h("span", {}, txt)
					},
					minWidth: 120
				},
				{
					title: "状态",
					// isDisable: 0 启用，1 禁用,默认为0.
					render: (h, params) =>
						h(
							"span",
							{
								style: {
									color: params.row.isDisable ? "#f01d03" : "#666"
								}
							},
							params.row.isDisable ? "已禁用" : "已启用"
						),
					minWidth: 100
				},
				{
					title: "启动流程角色",
					key: "roleIds",
					minWidth: 150,
					render: (h, params) => {
						const arr = params.row.roleIds ? params.row.roleIds.split(",") : []
						let textroles = ""
						if (params.row.startControl) {
							const roles = arr.map(item => {
								const obj = this.roleList.find(role => role.id === item)
								if (obj) {
									return obj.name
								}
								return item
							})
							textroles = roles.join(",")
						} else {
							textroles = "全部角色"
						}
						return h("span", {}, textroles)
					}
				},
				{
					title: "本流程适用的项目类型",
					key: "projectConfigIds",
					minWidth: 150,
					render: (h, params) => {
						const arr = params.row.projectConfigIds || []
						let textTypes = ""
						if (params.row.isCommon === 0) {
							const types = arr.map(item => {
								const obj = this.projTypeList.find(type => type.id === item)
								if (obj) {
									return obj.typeName
								}
								return item
							})
							textTypes = types.join(",")
						} else {
							textTypes = "全部项目类型"
						}
						return h("span", {}, textTypes)
					}
				},
				{
					title: "描述",
					key: "description",
					minWidth: 120
				},
				{
					title: "操作",
					key: "action",
					minWidth: 200,
					// fixed: 'right',
					renderButton: params => {
						const actionList = [
							{
								label: "复制",
								on: {
									click: this.handleCopyShow
								}
							},
							{
								label: "修改",
								on: {
									click: this.handleUpdateShow
								}
							},
							{
								label: "查看流程",
								on: {
									click: this.handleViewPage
								}
							},
							{
								label: "表单排序",
								on: {
									click: this.handleFormOrderShow
								}
							},
							{
								label: "删除",
								on: {
									click: this.handleDel
								},
								confirm: {
									title: "您确认删除这条数据吗？"
								}
							},
							{
								label: params.row.isDisable ? "启用" : "禁用",
								on: {
									click: this.handleSwitchState
								},
								confirm: {
									title: `您确认${params.row.isDisable ? "启用" : "禁用"}该流程吗？`
								}
							}
						]
						if (
							this.systemActionPermissions.indexOf(
								"btn_setting_process_add"
							) === -1
						) {
							actionList[1] = null // 修改
							actionList[3] = null // 表单排序
							actionList[4] = null // 删除
							actionList[5] = null // 启用 禁用
						}
						if (params.row.used) {
							actionList[4] = null // 删除
						}
						return actionList.filter(item => !!item)
					}
				}
			],
			loading: false,
			addVisible: false,
			formOrderVisible: false,
			processOrderVisible: false,
			updateId: "",
			formOrderId: "",
			processOrderId: "",
			searchParams: {
				category: "",
				nameLike: "",
				isDisable: 0
			},
			oldSearchParams: {
				category: "",
				nameLike: "",
				isDisable: 0
			},
			type: "",
			roleList: [],
			projTypeList: [], // 项目类型列表
			filterListData: []
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 130,
			projectStepInfo: state => state.user.projectStepInfo
		}),
		...mapState("permission", ["systemActionPermissions"]),
		...mapState("user", ["processList"])
	},
	created() {
		this.initList()
		this.getProjType()
	},
	methods: {
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetPage({ ...this.oldSearchParams })
				if (res) {
					this.listData = res.data
					this.filterListData = res.data.filter(item => item.showFlag)
				}
				const res2 = await apiGetRoleList()
				if (res2) {
					this.roleList = res2.data || []
				}
				this.loading = false
			})
		},
		async getProjType() {
			// 获取 新增项目类型 列表
			const res = await apiGetProjTypeList({ isDesigned: 1 }) // 只显示已配置过表单的项目类型。
			if (res) {
				this.projTypeList = res.data || []
			}
		},
		// 新增显示
		handleAddShow() {
			this.type = ""
			this.addVisible = true
			this.updateId = ""
		},
		// 新增成功刷新列表
		handleAddOk() {
			this.type = ""
			this.addVisible = false
			this.initList()
		},
		// 流程拖拽排序
		handleSort() {
			this.processOrderVisible = true
		},
		// 返回流程管理页面
		handleToProcess() {
			this.$router.push({
				name: "projectProcess",
				params: {
					projectId: this.$route.query.projectId
				}
			})
		},
		handleProcessSortOk() {
			this.processOrderVisible = false
			this.initList()
		},
		// 表单排序显示
		handleFormOrderShow({ row }) {
			this.formOrderVisible = true
			this.formOrderId = row.id
		},
		// 查看流程显示
		handleViewPage({ row }) {
			window.open(
				`${process.env.VUE_APP_REQUEST_BASE_URL}/flowable/modeler.html?modelId=${row.id}&token=${this.$store.state.user.token}`
			)
		},
		// 修改显示
		handleUpdateShow({ row }) {
			this.type = ""
			this.addVisible = true
			this.updateId = row.id
		},
		handleCopyShow({ row }) {
			this.addVisible = true
			this.type = "copy"
			this.updateId = row.id
		},
		// 删除
		handleDel({ row }) {
			this.$asyncDo(async () => {
				this.loading = true
				let res = false
				res = await apiDelete({ id: row.id })
				if (res) {
					this.$Message.success("操作成功！")
					this.initList()
				} else {
					this.loading = false
				}
			})
		},
		// 启用 禁用
		handleSwitchState({ row }) {
			this.$asyncDo(async () => {
				this.loading = true
				let res = false
				res = await apiDisableProcess({ modelId: row.id })
				if (res) {
					this.$Message.success("操作成功！")
					this.initList()
				} else {
					this.loading = false
				}
			})
		},
		// 重置
		reset() {
			this.searchParams = {
				category: "",
				nameLike: "",
				isDisable: 0
			}
			this.searchHandle()
		},
		// 搜索
		searchHandle() {
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.$nextTick(() => {
				this.initList()
			})
		}
	},
	watch: {}
}
</script>
<style lang="less">
.process-index-wrap {
	.ivu-table-fixed-right {
		.ivu-table-cell {
			padding: 0 8px;
		}
	}
}
</style>
