import { render, staticRenderFns } from "./ProcessOrder.vue?vue&type=template&id=8069dad0&scoped=true"
import script from "./ProcessOrder.vue?vue&type=script&lang=js"
export * from "./ProcessOrder.vue?vue&type=script&lang=js"
import style0 from "./ProcessOrder.vue?vue&type=style&index=0&id=8069dad0&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8069dad0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\KRHealth\\code\\develop\\kr-web-ctms\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8069dad0')) {
      api.createRecord('8069dad0', component.options)
    } else {
      api.reload('8069dad0', component.options)
    }
    module.hot.accept("./ProcessOrder.vue?vue&type=template&id=8069dad0&scoped=true", function () {
      api.rerender('8069dad0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Setting/Process/ProcessOrder.vue"
export default component.exports